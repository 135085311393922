import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
// import { FirestoreAdd } from "../utils/firebase"
import { SendToSlack } from "../utils/sendToSlack"
import { useStaticQuery, graphql } from "gatsby"

const Confirm = ({
  values,
  isConfirmationVisible,
  setIsConfirmationVisible,
  hideConfirmation,
  handleSubmit,
  reset,
  setIsSendComplete,
}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          slackUrl
        }
      }
    }
  `)
  const displayStyle = {
    display: isConfirmationVisible ? "block" : "none",
  }

  const onSendData = () => {
    // FirestoreAdd(values)
    SendToSlack(values, site)

    setIsConfirmationVisible(false)
    reset()
    scrollTo(".contact__hero")
    setIsSendComplete(true)
    setTimeout(() => {
      setIsSendComplete(false)
    }, 10000)
  }

  return (
    <section className="confirm" style={displayStyle}>
      <div className="confirm__container">
        <form onSubmit={handleSubmit(onSendData)}>
          <h1 className="contact-form__title">確認画面</h1>
          <div className="contact-form__group">
            <label htmlFor="name">お名前</label>
            <input type="text" name="name" value={values.name} disabled />
          </div>
          <div className="contact-form__group">
            <label htmlFor="kana">フリガナ</label>
            <input type="text" name="kana" value={values.kana} disabled />
          </div>
          <div className="contact-form__group">
            <label htmlFor="email">メールアドレス</label>
            <input type="email" name="email" value={values.email} disabled />
          </div>
          <div className="contact-form__group">
            <label htmlFor="addr">ご住所</label>
            <input type="text" name="addr" value={values.addr} disabled />
          </div>
          <div className="contact-form__group">
            <label htmlFor="build">マンション名・部屋番号等</label>
            <input type="text" name="build" value={values.build} disabled />
          </div>
          <div className="contact-form__group">
            <label htmlFor="tel">お電話番号</label>
            <input type="tel" name="tel" value={values.tel} disabled />
          </div>
          <div className="contact-form__group">
            <label htmlFor="fax">FAX番号</label>
            <input type="tel" name="fax" value={values.fax} disabled />
          </div>
          <div className="contact-form__group">
            <label htmlFor="consul">お問い合わせ内容</label>
            <textarea name="consul" rows="10" value={values.consul} disabled />
          </div>
          <div className="form-btn">
            <button type="submit" className="form-btn-submit">
              送信
            </button>
            <button className="form-btn-close" onClick={hideConfirmation}>
              閉じる
            </button>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Confirm
