import React, { useState } from "react"
import { useForm } from "react-hook-form"
import scrollTo from "gatsby-plugin-smoothscroll"

import Confirm from "./confirm"
import SendComplete from "./sendComplete"

const ContactForm = () => {
  const { register, handleSubmit, reset, watch, errors, getValues } = useForm()
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)
  const [isSendComplete, setIsSendComplete] = useState(false)

  const hideConfirmation = () => setIsConfirmationVisible(false)
  const onSubmitData = e => {
    // e.preventDefault()
    setIsConfirmationVisible(true)
    scrollTo(".contact__container")
  }

  const displayStyle = {
    display: isConfirmationVisible ? "none" : "block",
  }

  const Name = watch("name")
  const Kana = watch("kana")
  const Email = watch("email")
  const Tel = watch("tel")
  const Consul = watch("consul")

  return (
    <>
      <SendComplete
        isSendComplete={isSendComplete}
        setIsSendComplete={setIsSendComplete}
      />
      <section className="contact-form" style={displayStyle}>
        <div className="contact-form__container">
          <form onSubmit={handleSubmit(onSubmitData)}>
            <h1 className="contact-form__title">お問い合わせフォーム</h1>
            <div className="contact-form__group">
              <label htmlFor="name">
                お名前
                <span className={`${Name ? "is-ok" : "is-required"}`}>
                  {Name ? "OK" : "必須"}
                </span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="氏名を入力"
                ref={register({ required: true })}
              />
              {errors.name && <p className="formError">氏名は必須です。</p>}
            </div>
            <div className="contact-form__group">
              <label htmlFor="kana">
                フリガナ
                <span className={`${Kana ? "is-ok" : "is-required"}`}>
                  {Kana ? "OK" : "必須"}
                </span>
              </label>
              <input
                type="text"
                name="kana"
                placeholder="フリガナを入力"
                ref={register({ required: true })}
              />
              {errors.kana && <p className="formError">フリガナは必須です。</p>}
            </div>
            <div className="contact-form__group">
              <label htmlFor="email">
                メールアドレス
                <span
                  className={`${
                    Email && !errors.email ? "is-ok" : "is-required"
                  }`}
                >
                  {Email && !errors.email ? "OK" : "必須"}
                </span>
              </label>
              <input
                type="email"
                name="email"
                placeholder="メールアドレスを入力"
                ref={register({
                  required: true,
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                })}
              />
              {errors.email && (
                <p className="formError">メールアドレスは必須です。</p>
              )}
            </div>
            <div className="contact-form__group">
              <label htmlFor="addr">ご住所</label>
              <input
                type="text"
                name="addr"
                placeholder="住所を入力"
                ref={register({ required: false })}
              />
            </div>
            <div className="contact-form__group">
              <label htmlFor="build">マンション名・部屋番号等</label>
              <input
                type="text"
                name="build"
                placeholder="マンション（ビル）名・部屋番号"
                ref={register({ required: false })}
              />
            </div>
            <div className="contact-form__group">
              <label htmlFor="tel">
                お電話番号
                <span className={`${Tel ? "is-ok" : "is-required"}`}>
                  {Tel ? "OK" : "必須"}
                </span>
              </label>
              <input
                type="tel"
                name="tel"
                placeholder="電話番号を入力"
                ref={register({ required: true })}
              />
              {errors.tel && (
                <p className="formError">お電話番号は必須です。</p>
              )}
            </div>
            <div className="contact-form__group">
              <label htmlFor="fax">FAX番号</label>
              <input
                type="tel"
                name="fax"
                placeholder="FAX番号を入力"
                ref={register({ required: false })}
              />
            </div>
            <div className="contact-form__group">
              <label htmlFor="consul">
                お問い合わせ内容
                <span
                  className={`${
                    Consul && !errors.consul ? "is-ok" : "is-required"
                  }`}
                >
                  {Consul && !errors.consul ? "OK" : "必須"}
                </span>
              </label>
              <textarea
                name="consul"
                rows="10"
                placeholder="お問い合わせ内容を500文字以内で入力してください。"
                ref={register({
                  required: true,
                  rows: 8,
                  minLength: 1,
                  maxLength: 500,
                })}
              />
              {errors.consul && (
                <p className="formError">
                  お問い合わせ内容500文字以内で入力してください。
                </p>
              )}
            </div>
            <div className="form-btn">
              <button type="submit" className="form-btn-submit">
                内容確認
              </button>
              <button type="reset" className="form-btn-reset">
                クリア
              </button>
            </div>
          </form>
        </div>
      </section>

      {isConfirmationVisible && (
        <Confirm
          values={getValues()}
          isConfirmationVisible={isConfirmationVisible}
          setIsConfirmationVisible={setIsConfirmationVisible}
          hideConfirmation={hideConfirmation}
          handleSubmit={handleSubmit}
          reset={reset}
          setIsSendComplete={setIsSendComplete}
        />
      )}
    </>
  )
}

export default ContactForm
