import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/heroImage"
import ContactForm from "../components/contactForm"

const Contact = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      info: file(relativePath: { eq: "info.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
    }
  `)

  const description =
    "大阪の司法書士事務所、司法書士法人みつわ合同事務所は、皆様からのご意見やご質問を随時受付しております。日常でのお困りごとやお悩み、司法書士に聞いてみたいことなどがありましたら、いつでもお気軽にお問い合わせください。また、SNSアカウントを開設しておりますので、ご利用ください。"

  return (
    <Layout>
      <SEO
        description={description}
        title={"お問い合わせ　相談 | 司法書士　大阪"}
        pagepath={location.pathname}
        pageimg={data.info.childImageSharp.original.src}
        pageimgw={data.info.childImageSharp.original.width}
        pageimgh={data.info.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"contact"}
          fluid={data.info.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"お問い合わせ"}
        />
        <ContactForm />
        <div className="link__area">
          <Link to={"/"}>
            <button className="link__btn">Topページ</button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
