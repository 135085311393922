import React from "react"
import { Link } from "gatsby"

const SendComplete = ({ isSendComplete, setIsSendComplete }) => {
  const beforeSendStyle = {
    display: isSendComplete ? "none" : "block",
  }
  const sendCompStyle = {
    display: isSendComplete ? "block" : "none",
  }
  return (
    <>
      <section className="contact" style={beforeSendStyle}>
        <div className="contact__container">
          <h1 className="contact__title">お問い合わせ</h1>
          <div className="contact__desc">
            <p>
              お問い合わせの際は「
              <Link to={"/privacy-policy/"}>個人情報保護方針</Link>
              」をお読みになり、ご同意の上お問い合わせください。
              <br />
              お問い合わせ内容によっては、回答にお時間をいただく場合や、
              書面、お電話での回答となる場合がございますので、予めご了承ください。
            </p>
          </div>
        </div>
      </section>
      <section className="contact" style={sendCompStyle}>
        <div className="contact__container">
          <h1 className="contact__title">受付完了</h1>
          <div className="contact__desc">
            <p>
              内容によっては、回答にお時間がかかる場合や、お問い合わせ内容の確認のために
              ご連絡をする場合がございます。
              <br />
              また、ご連絡先の記載に誤りがある場合等、ご回答できない場合がありますので、お急ぎの方はお電話でお問い合わせください。
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default SendComplete
