import fetch from "node-fetch"

export const SendToSlack = (values, site) => {
  const payload = {
    text: `
            ホームページよりお問い合わせ
お名前： ${values.name}
カナ: ${values.kana}
e-mail: ${values.email}
ご住所: ${values.addr}
マンション名・お部屋番号: ${values.build}
お電話番号: ${values.tel}
FAX番号: ${values.fax}
ご相談内容: ${values.consul}
        `,
  }

  const url = site.siteMetadata.slackUrl
  fetch(url, {
    method: "POST",
    body: JSON.stringify(payload),
  }).catch(() => {
    alert(`送信エラー！
        申し訳ありませんがお電話でお問い合わせくださいますようお願い致します。`)
  })
}
